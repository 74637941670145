import querystring from 'querystring';
import axios from './axios';
import logger from 'log-level';
import { isArray } from '../helpers/javascript';
export var find = function find(_ref) {
  var url = _ref.url,
      method = _ref.method,
      _ref$headers = _ref.headers,
      headers = _ref$headers === void 0 ? {} : _ref$headers,
      query = _ref.query;
  return new Promise(function (resolve, reject) {
    if (!url) {
      logger.error('Url must not be empty');
      reject();
    }

    if (!method) {
      logger.error('Method must not be empty');
      reject();
    }

    var queryParams = '';
    if (query && typeof query === 'object' && query.constructor === Object) queryParams = "?".concat(querystring.stringify(query));
    axios({
      url: "".concat(url).concat(queryParams),
      method: method,
      headers: headers
    }).then(function (response) {
      if (isArray(response.data)) resolve(response.data);else {
        logger.error('Elements must be an array');
        reject();
      }
    })["catch"](function () {
      logger.error('Ups fail to load elements');
      reject();
    });
  });
};