import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(function (theme) {
  return {
    formAlert: {
      marginTop: 6
    },
    root: {
      position: 'relative',
      '& .MuiPaper-root': {
        width: 636,
        padding: 30,
        borderRadius: 12,
        boxShadow: '0px 3px 18px #00000029',
        '@media only screen and (max-width: 650px)': {
          margin: 0,
          height: '100%',
          maxHeight: '100%',
          paddingTop: '50px'
        }
      }
    },
    subTitle: {
      fontSize: 18,
      lineHeight: '24px',
      paddingTop: 10,
      color: '#414042',
      fontWeight: '400',
      textAlign: 'center',
      '@media only screen and (max-width: 650px)': {
        fontSize: 18
      }
    },
    paddingBottom: {
      paddingBottom: 20
    },
    title: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: '40px',
      margin: '0 6px',
      color: '#414042',
      '@media only screen and (max-width: 768px)': {
        margin: '0 0px'
      },
      '@media only screen and (max-width: 650px)': {
        fontSize: 18,
        margin: '0 10px'
      }
    },
    socialContainer: {
      display: 'flex',
      marginTop: 27
    },
    formControl: {
      display: 'flex',
      marginBottom: 16,
      '& label': {
        width: '100%'
      }
    },
    socialButton: {
      flex: 1,
      fontSize: 18,
      backgroundColor: '#f2f2f2',
      textTransform: 'none',
      padding: '6px 0px',
      borderRadius: 45,
      color: '#757575',
      fontWeight: 400,
      '&.form-button': {
        // backgroundColor: '#3598FB',
        backgroundImage: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
        color: '#fff',
        '&:disabled': {
          // color: '#1111',
          // backgroundColor: '#ececec'
          color: '#fff',
          opacity: '.3'
        },
        '&:hover': {
          // backgroundColor: '#0086FF'
          backgroundImage: '-webkit-linear-gradient(180deg, #764dbd, #026cce)'
        }
      },
      '& svg': {
        height: 18,
        marginRight: 12
      },
      '&:hover': {
        backgroundColor: '#E9E9E9'
      }
    },
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 560,
      '@media only screen and (max-width: 768px)': {
        width: 400
      },
      '@media only screen and (max-width: 425px)': {
        width: '100%'
      }
    },
    container__medium: {
      width: 420
    },
    formTitle: {
      marginBottom: 6,
      fontSize: 16
    },
    formInput: {
      border: 'solid 1px #DFE3E7',
      width: '100%',
      borderRadius: 8,
      fontSize: 18,
      paddingLeft: 13,
      height: 44,
      '&:focus': {
        outline: 'none',
        border: 'solid 1px #009FBF',
        borderRadius: 8
      },
      '&::-webkit-input-placeholder': {
        color: '#d0d0d0',
        fontSize: 14
      },
      '&:-ms-input-placeholder': {
        color: '#d0d0d0',
        fontSize: 14
      },
      '&::placeholder': {
        color: '#d0d0d0',
        fontSize: 14
      },
      '&.error': {
        border: 'solid 1px #f44336!important'
      }
    },
    form: {
      marginTop: 24
    },
    legal: {
      marginTop: 24,
      fontSize: 12,
      textAlign: 'center',
      '& a': {
        margin: '0 6px'
      }
    },
    login: {
      textAlign: 'center',
      marginTop: 24,
      fontSize: 14,
      color: '#414042',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    forgot: {
      textAlign: 'center',
      fontSize: 14,
      color: '#707070',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    marginTop: {
      marginTop: 24
    },
    loginButton: {
      backgroundColor: 'transparent',
      color: '#0086FF',
      textDecoration: 'underline',
      textTransform: 'none',
      padding: 0,
      fontSize: 14,
      marginLeft: 6,
      minWidth: 'unset',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        color: '#707070'
      },
      fontWeight: 400
    },
    linkButton: {
      backgroundColor: 'transparent',
      color: '#707070',
      textDecoration: 'underline',
      textTransform: 'none',
      padding: 0,
      fontSize: 14,
      minWidth: 'unset',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        color: '#3399FF'
      },
      fontWeight: 400
    },
    divider: {
      marginTop: 25,
      color: '#707070',
      fontSize: 18,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      '&::before,&::after': {
        content: '""',
        flex: 1,
        borderBottom: '2px solid #00000029'
      },
      '&::before': {
        marginRight: '28px'
      },
      '&::after': {
        marginLeft: '28px'
      }
    },
    label: {
      fontSize: 18,
      color: '#707070'
    },
    alert: {
      marginTop: 24
    }
  };
});