import axios from './axios';
import { api } from './app.config';
import { isArray } from '../helpers/javascript';
export var types = function types(_ref) {
  var team = _ref.team,
      jwt = _ref.jwt;
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/entity-types"),
      headers: {
        Authorization: "Bearer ".concat(jwt),
        'X-Mission-Control-Team': team
      }
    }).then(function (response) {
      if (isArray(response.data)) resolve(response.data);else reject();
    })["catch"](function () {
      reject();
    });
  });
};